import React, { useContext, useState } from "react";
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Divider,
} from "@mui/material";
import GoogleButton from "react-google-button";
import { FcGoogle } from "react-icons/fc";
import { FaTwitter, FaFacebook, FaApple } from "react-icons/fa";
import { ThemeContext } from "../context/theme";
import Copyright from "./copyright";
import {
  signInWithEmailAndPassword,
  signInWithProvider,
} from "../firebase/firebase";

// @ts-expect-error Not a module
import logo from "../assets/logo.svg";
import { FirebaseError } from "firebase/app";

export default function SignIn() {
  const [loginErrors, setLoginErrors] = useState({});

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    setLoginErrors({});
    try {
      const userCredential = await signInWithEmailAndPassword(
        data.get("email") as string,
        data.get("password") as string
      );
    } catch (e) {
      let error = e as FirebaseError;
      console.log(error.message);
      if (error.message.includes("auth/invalid-email")) {
        setLoginErrors({ email: "Invald email !" });
      } else if (error.message.includes("auth/user-not-found")) {
        setLoginErrors({ email: "User not found !" });
      } else if (error.message.includes("auth/wrong-password")) {
        setLoginErrors({ password: "Invald password !" });
      } else {
        setLoginErrors({ password: "Unknown error" });
      }
    }
  };

  const { theme } = useContext(ThemeContext);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, width: "auto", height: "auto" }}
          src={logo}
        ></Avatar>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={loginErrors["email"] ? true : false}
            helperText={loginErrors["email"]}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={loginErrors["password"] ? true : false}
            helperText={loginErrors["password"]}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Divider variant="middle"> OR </Divider>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 3 }}
          startIcon={<FcGoogle />}
          onClick={() => signInWithProvider("google")}
        >
          Sign In With Google
        </Button>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 1, color: "#1DA1F2" }}
          startIcon={<FaTwitter />}
          onClick={() => signInWithProvider("twitter")}
        >
          Sign In With Twitter
        </Button>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 1, color: "black" }}
          startIcon={<FaApple />}
          onClick={() => signInWithProvider("apple")}
        >
          Sign In With Apple
        </Button>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 1, mb: 2, color: "#3b5998" }}
          startIcon={<FaFacebook />}
          onClick={() => signInWithProvider("facebook")}
        >
          Sign In With Facebook
        </Button>
      </Box>
      <Copyright sx={{ mt: 2, mb: 4 }} />
    </Container>
  );
}
