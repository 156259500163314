import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import Layout from "../components/layout/layout";
import LogInComponent from "../components/login";
import { AuthContext } from "../context/auth";
import { SignInWithJWTStates, useSignInWithJWT } from "../firebase/firebase";

function LogInPage({ location }) {
  const { user, isLoading } = useContext(AuthContext);

  let params = new URLSearchParams(location.search);
  const token = params.get("token");
  const { status } = useSignInWithJWT(token as string);

  useEffect(() => {
    if (status === SignInWithJWTStates.SUCESS) {
      // Do nothing here, auth will update and redirect below will take place
    } else if (
      [
        SignInWithJWTStates.ERROR,
        SignInWithJWTStates.FAILED_GET_CUSTOM_TOKEN,
        SignInWithJWTStates.FAILED_LOGIN_CUSTOM_TOKEN,
      ].includes(status)
    ) {
      navigate("/tokenLoggedOut");
    }
  }, [status]);

  useEffect(() => {
    if (user) {
      navigate("/dashboard/report");
    }
  }, [isLoading, user]);

  return (
    <Layout>
      <LogInComponent />
    </Layout>
  );
}

export default LogInPage;
